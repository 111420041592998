import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';

// koneksi
import { Post, Get, Delete, PostMain, GetMain, DeleteMain, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/site.css';

const frmDef = {
	uuid: '',
	nama: '',
	version_desa_app: '',
	version_min_desa: '',
	link_playstore: '',
	link_appstore: '',
}
class JenisAplikasi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Jenis Aplikasi",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name: '',

			dataCari: {
				q: '',
			},
			frmStatusOtp: [
				{
				  value: 1,
				  label: 'Aktif',
				},
				{
				  value: 0,
				  label: 'Tidak Aktif',
				},
			],
			selectedStatusOtp: null,

			loadingPreview: false,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		this.setState({
			loadingPreview: true,
		})
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}

		PostMain('main/web/jenis-aplikasi' + link, null, this.state.dataCari, (lihat) => {
			if (lihat.data.result.data) {
				this.setState({ 
					data: lihat.data.result.data, 
					pagination: lihat.data.result,
					loadingPreview: false
				});
				this.pagination(lihat.data.result);
			}
			else{
				this.setState({ loadingPreview: false, });
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let addKeldes = {
				q: e.target.value,
			};
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.setState({
			loadingPreview: true,
			status:{
				...this.state.status,
				btnForm: true,
			}
		})
		if(this.state.selectedStatusOtp){
			this.forceUpdate();
			let addData = {
				uuid: document.getElementById("uuid").value,
				nama: document.getElementById("nama").value,
				version_desa_app: document.getElementById("version_desa_app").value,
				version_min_desa: document.getElementById("version_min_desa").value,
				link_playstore: document.getElementById("link_playstore").value,
				link_appstore: document.getElementById("link_appstore").value,
				otp: this.state.selectedStatusOtp.value
			};
	
			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addData.uuid === '') {
				psn = "Tambah";
				resstat = 200;
				addData.uuid = null;
	
			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
	
			try {
				PostMain('main/web/jenis-aplikasi/' + metode, addData.uuid, addData, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status === resstat) {
						let param_uuid = null
						if(metode === 'create'){
							addData['uuid'] = res.data.result
						}
						if(metode === 'update'){
							param_uuid = addData['uuid']
						}
	
						Post('jenis-aplikasi/' + metode, param_uuid, addData, (res2) => {
							if (res2.status === resstat) {
								this.setState({ 
									loadingPreview: false,
									status:{
										...this.state.status,
										form: false,
									},
									show: true, 
									basicType: 'success', 
									basicTitle: 'Data Jenis Aplikasi', 
									pesanAlert: 'Berhasil ' + psn + ' Data',
								});
	
							}
							else{
								this.setState({ 
									loadingPreview: false,
									show: true, 
									basicType: 'danger', 
									basicTitle: 'Data Jenis Aplikasi', 
									pesanAlert: 'Gagal ' + psn + ' Data' 
								});
							}
						})
					}
					else {
						this.setState({ 
							loadingPreview: false,
							show: true, 
							basicType: 'danger', 
							basicTitle: 'Data Jenis Aplikasi', 
							pesanAlert: 'Gagal ' + psn + ' Data' 
						});
					}
					this.componentDidMount();
				});
			} catch (e) {
				console.log('Error')
				console.log(e)
				this.setState({ 
					loadingPreview: false,
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Data Jenis Aplikasi', 
					pesanAlert: 'Gagal ' + psn + ' Data' 
				});
			}
		}
		else{
			this.setState({ 
				loadingPreview: false,
				show: true, 
				basicType: 'danger', 
				basicTitle: 'Data Jenis Aplikasi', 
				pesanAlert: 'Data Tidak Lengkap' 
			});
		}
	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		this.setState({
			loadingPreview: true
		})
		this.bukaForm();
		this.setState({
			judul: "Ubah Jenis Aplikasi",
			status:{
				...this.state.status,
				btnAksi: false,
			},
			dt:{
				uuid: data.uuid,
				nama: data.nama,
				version_desa_app: data.version_desa_app,
				version_min_desa: data.version_min_desa,
				link_playstore: data.link_playstore,
				link_appstore: data.link_appstore,
			},
			selectedStatusOtp: {
				value: data.otp,
				label: data.otp === 1 ? 'Aktif' : 'Tidak Aktif'
			},
			loadingPreview: false,
		})
		this.forceUpdate();
		
	}
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		this.setState({
			loadingPreview: true
		})
		let psn = "Hapus";
		try {
			DeleteMain('main/web/jenis-aplikasi/delete', id, (res) => {
				if (res == 200) {
					Delete('jenis-aplikasi/delete', id, (res2) => {
						if (res2 == 200) {
							this.setState({ loadingPreview: false, show: true, basicType: 'success', basicTitle: 'Data Jenis Aplikasi', pesanAlert: 'Berhasil ' + psn + ' Data' });
						}
						else {
							this.setState({ loadingPreview: false, show: true, basicType: 'danger', basicTitle: 'Data Jenis Aplikasi', pesanAlert: 'Gagal ' + psn + ' Data' });
						}
					})
				}
				else {
					this.setState({ loadingPreview: false, show: true, basicType: 'danger', basicTitle: 'Data Jenis Aplikasi', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			})
		} catch (e) {
			console.log('Error')
			console.log(e)
			this.setState({ 
				loadingPreview: false,
				show: true, 
				basicType: 'danger', 
				basicTitle:  'Data Jenis Aplikasi', 
				pesanAlert: 'Gagal ' + psn + ' Data'
			});
		}
	}
	// ========================================================================

	bukaForm = () => {
		if ('LANJUTIN BOS') {
			
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	
	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeSelectValue = (e, jenis) => {
		if (e) {
		  this.setState({ 
			[jenis]: e 
		  });
		} else {
		  this.setState({ 
			[jenis]: null 
		  });
		}
	  };

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			nama: '',
		}

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style:{
					width: '15px',
				},
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			{
				dataField: 'nama',
				text: 'Nama Aplikasi',
				sort: true
			},
			{
				dataField: 'version_desa_app',
				text: 'Version Desa App',
				sort: true
			},
			{
				dataField: 'version_min_desa',
				text: 'Version Min Desa',
				sort: true
			},
			{
				dataField: 'link_playstore',
				text: 'Playstore',
				sort: true
			},
			{
				dataField: 'link_appstore',
				text: 'Appstore',
				sort: true
			},
			{
				dataField: 'otp',
				text: 'Status OTP',
				sort: true,
				formatter: (cell, obj) => {
					let show = ''

					if(obj.otp === 1){
						show = 'Aktif'
					}
					else{
						show = 'Tidak Aktif'
					}

					return show;
				},
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style:{
					width: '120px',
				},
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'nama',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Jenis Aplikasi" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Jenis Aplikasi..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Jenis Aplikasi", dt: frmDef, selectedStatusOtp: null, }); this.bukaForm(); }}>Tambah Data</Button>
										</div>
									</div>

									<ToolkitProvider
										keyField="uuid"
										data={data}
										columns={columns}
										search >
										{
											props => (
												<div>
													<BootstrapTable
														{...props.baseProps}
													/>
												</div>
											)
										}
									</ToolkitProvider>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				{/* Loading preview */}
				<SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../../assets/images/tes-loading.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>

				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Nama Aplikasi" defaultValue={this.state.dt.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Aplikasi</Label>
								<Col sm='9'>
									<input className="form-control" id="nama" type="text" placeholder="Contoh: Digidesa" defaultValue={this.state.dt.nama} />
								</Col>
							</FormGroup>
							
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Version Desa App </Label>
								<Col sm='9'>
									<input className="form-control" id="version_desa_app" type="text" placeholder="Contoh: 1.0.0" defaultValue={this.state.dt.version_desa_app} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Version Min Desa App </Label>
								<Col sm='9'>
									<input className="form-control" id="version_min_desa" type="text" placeholder="Contoh: 1.0.0" defaultValue={this.state.dt.version_min_desa} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link Download PlayStore </Label>
								<Col sm='9'>
									<input className="form-control" id="link_playstore" type="text" placeholder="Contoh: https://play.google.com/store/apps/details?id=com.solusidatamadani.digidesa" defaultValue={this.state.dt.link_playstore} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link Download AppStore </Label>
								<Col sm='9'>
									<input className="form-control" id="link_appstore" type="text" placeholder="Contoh: https://apps.apple.com/id/app/digidesa/id1577733972" defaultValue={this.state.dt.link_appstore} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status OTP</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.changeSelectValue(e, 'selectedStatusOtp')}
										value={this.state.selectedStatusOtp}
										name="status_otp"
										options={this.state.frmStatusOtp}
										placeholder="Pilih Status OTP"
										isClearable
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default JenisAplikasi;
